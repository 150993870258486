import React, {useContext} from 'react';
import "./CountDown.styles.scss";
import Timer from "../../Components/Timer/Timer.component";
import GlobalSummaryContext from "../../Contexts/GlobalSummaryContext";
import {DateTime} from 'luxon';
import InfoCard from "../../Components/InfoCard/InfoCard.component";
import Iframe from "react-iframe";

window.dt = DateTime;
const CountDown = () => {
    const {ended_before} = useContext(GlobalSummaryContext);
    window.ltdt = DateTime;
    return (
        <div id="countdown">
    <section className="section-gap">
        <div className="container">            
            <div className="row text-center">
                <div className="col-12">
                    <div className="section-title" style={{marginBottom: '3rem'}}>
                        <h3 className="f36-lg f30-sm font-name big-heading mt-5 mt-3-sm m0 mb-md-3">Pool Information</h3>
                        <div className="f18-lg f16-sm line1-6 description mb-7" hidden>Business-to-consumer lean startup incubator iPad growth hacking<br/> influenced research &amp; development user experience.</div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-md-6 d-flex flex-wrap mb-5" style={{alignContent: "space-evenly"}}>
                    <div className="card text-center py-md-1 px-md-1 wow fadeInUp countdown-wrap" data-wow-delay=".2s" >
                        <div className="card-header">
                            <div className="countdown-heading"><h3 className="f24-lg mb4 heading">Next Epoch</h3><div className="sub-heading">Maximize your reward token by delegating</div></div>
                        </div>
                        <div className="card-body">
                            {ended_before ? <Timer end={DateTime.fromSeconds(parseInt(ended_before)).plus({days:5}).toMillis()}/>: ""}
                            <div className="card-body" style={{display:'none'}}>
                                <p className="mt-md-4 wow fadeInUp" data-wow-delay=".3s">Latest Saturation Levels</p>
                                <div id="counting-date" className="count-down mb-5 wow fadeInUp" data-wow-delay=".4s"/>
                                <div className="ico-progress-row wow fadeInUp" data-wow-delay=".5s">                                    
                                    <div className="progress ico-progress">
                                        <div className="progress-bar progress-purple-light" id="saturation-progress" role="progressbar" style={{width: '100%', borderRadius: '1.25rem'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                            <span className="progress-caption-top">100%</span>
                                        </div>
                                    </div>
                                    <div className="text-left mt-2 mb-5">
                                        <small>0%</small>
                                        <small className="float-right">100%</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-12 col-gap mb-md-0 mb-3">
                    <div className="row text-center mb-lg-0 mb-5">
                        <div className="col-6 mt-0 pt-0">
                            <div className="row">
                                <div className="col-12 mb-4">
                                    <InfoCard value={"SCAN"} img={"/assets/img/marketing-analysis-new-icon1.png"} delay={0.2} title={'Ticker'}/>
                                </div>
                                <div className="col-12">
                                    <InfoCard value={"2%"} img={"/assets/img/contact-form-new-icon2.png"} delay={0.3} title={'Margin'}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="row">
                                <div className="col-12 mb-4">
                                    <InfoCard value={"Every Epoch(~5 days)"} img={"/assets/img/smo-new-icon.png"} delay={0.4} title={'Payout Frequency'}/>
                                </div>
                                <div className="col-12">
                                    <InfoCard value={"4% - 12%"} img={"/assets/img/diamond.png"} delay={0.5} title={'Expected Reward Rate'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" hidden>
                <div className="col-12 text-center">
                    <Iframe
                        url={"https://66.94.100.70:3000/d-solo/K4z3N37w0rk/monitor-cardano-node?orgId=1&refresh=5s&from=1632593049749&to=1632603849749&theme=light&panelId=96"}
                        width="450" height="200" frameBorder="0"
                        allowFullScreen={true}
                    />
                </div>
            </div>
        </div>
    </section>
</div>
    );
}

export default CountDown;