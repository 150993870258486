import React from "react";
const InfoCard = ({img, title, value, delay}) =>(
    <div className="card box-show py-md-5 wow fadeInUp" data-wow-delay={`${delay}s`} >
        <div className="card-body text-center">
            <img src={img} alt="" />
            <h3 className="text-navy-blue">{title}</h3>
            <p className="mb-0">{value}</p>
        </div>
    </div>
);
export default InfoCard;