import React from 'react';
import './Ticker.styles.scss';
const Ticker = () =>(
<div id="ticker">    
    <section className="section-gap">
        <div className="container">    
            <div className="row text-center justify-content-center">
                <div className="col-12">
                    <div className="section-title" style={{marginBottom: '3rem'}}>

                        <div className="card-body text-center">

                            <h4 className="text-navy-blue">Pool ID</h4>
                            <p className="mb-0 font-size-sm pool-id">1b229561688d2d9f2e9d16fb7eea258c2d764eeecb6e5d6743f3e2b4 <i className="bi bi-files text-navy-blue ml-1 text-bold" id="copypool" data-toggle="tooltip" data-placement="right" title="Click to copy"></i></p>
                        </div>                        
                    </div>
                </div>

            <div className="row text-md-left text-center align-items-center justify-content-center">
                <div className="col-md-6 col-12 wow fadeInUp d-flex align-items-center" data-wow-delay=".8s">
                    <div className="card text-center hover-flush bg-subtle">
                        <img className="card-img-top" src="/assets/img/daedalus-cardano.webp" alt="daedalus-cardano" />
                        <div className="card-body">
                            <h5 className="card-title">DAEDALUS</h5>
                            <p className="card-text" hidden>
                                <u>Daedalus</u> wallet is a desktop, full-node hierarchical deterministic (HD) wallet for ada cryptocurrency.

                                Easy installation with one-click setup of bundled Cardano node.

                                This means you can stake your ada in a  SCAN pool without your ADA ever leaving your wallet.

                                You always remain in control of your own funds.
                            </p>
                            <a href="https://daedaluswallet.io/" target="_blank" rel="noreferrer"  className="btn btn-warning">Download Wallet</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-12 wow fadeInUp d-flex align-items-center" data-wow-delay=".6s">
                    <div className="card text-center hover-flush bg-subtle">
                        <img className="card-img-top" src="/assets/img/yoroi.webp" alt="Yoroi" />
                        <div className="card-body">
                            <h5 className="card-title">YOROI</h5>
                            <p className="card-text" hidden>
                                Yoroi is a light wallet for Cardano. There is no need to download the blockchain when you open the wallet. So you are ready to send and receive transactions right away. Yoroi is supporting staking through delegation in the same way as Daedalus.
                                So you are able to select pools to delegate your stake within Yoroi.
                            </p>
                            <a href="https://yoroi-wallet.com/" target="_blank" rel="noreferrer"  className="btn btn-warning">Download Wallet</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    
</div>
);

export default Ticker;