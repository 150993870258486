import React,{useState} from 'react';
import emailjs,{ init } from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";
// init("user_fhlTdnDiGCJ3q1rTqRnFm");
init("user_yorQnuhIBt8g9V4gNrVmc");

function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [recaptcha, setRecaptcha] = useState(undefined);
    const [emailSent, setEmailSent] = useState(false);
    const recaptchaRef = React.createRef();
    /*const serviceId = 'service_j729eeq';
    const templateId = 'template_lidaoec';
    const userId = 'user_fhlTdnDiGCJ3q1rTqRnFm';
    const recaptchaSiteKey = '6LcA-IocAAAAACrZJj5mGyD5voxAipIUCPTrxAif';
    */
    const serviceId = 'service_xsz9jfc';
    const templateId = 'template_3bhyv9h';
    const userId = 'user_yorQnuhIBt8g9V4gNrVmc';
    const recaptchaSiteKey = '6Lca25QcAAAAADFgPCTLzC769J-na8PpRFFYs1Aj';

    const submit = (e) => {
        e.preventDefault();

        if (name && email && message && recaptcha) {
            const templateParams = {
                name,
                email,
                message,
                'g-recaptcha-response' : recaptcha
            };

            emailjs.send(serviceId, templateId, templateParams, userId)
                .then(response => console.log(response))
                .then(error => console.log(error));

            setName('');
            setEmail('');
            setMessage('');
            setEmailSent(true);
            recaptchaRef.current.reset();
        } else {
            alert('Please fill in all fields.');
        }
    }



    return (
        <div id="contact">
    
    <section className="section-gap telegram-section"/>
    <section className="section-gap-sm feature-gradient">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div id="contact-box" className="card text-dark py-md-3 px-md-4 wow fadeInUp" data-wow-delay=".2s">
                        <div className="card-body">
                            <div className="row justify-content-md-center text-center">

                                <div className="col-md-8 col-12">
                                    {emailSent ?
                                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                                            <strong>Thank you!</strong> Your message has been sent.
                                            <button type="button" className="close" data-dismiss="alert"
                                                    aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        :
                                        ""
                                    }
                                    <div className="py-3">
                                        <h3 className="mb-0">Contact Scan</h3>
                                    </div>

                                    <form id="contactForm" onSubmit={submit} className="contact-form text-left" data-toggle="validator" noValidate={true}>
                                        <div className="row">
                                            <div className=" col-md-6">
                                                <div className="form-group ">
                                                    <input type="text" id="name" className="form-control" placeholder="Name" required value={name} onChange={e => setName(e.target.value)} data-error="Please enter your name" />
                                                    <div className="help-block with-errors"/>
                                                </div>
                                            </div>

                                            <div className=" col-md-6">
                                                <div className="form-group ">
                                                    <input type="email" className="email form-control" id="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} required="" data-error="Please enter your email"/>
                                                    <div className="help-block with-errors"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className="form-group">
                                            <div className="controls">
                                                <input type="text" id="msg_subject" className="form-control" placeholder="Subject" value={sub} required="" data-error="Please enter your message subject"/>
                                                <div className="help-block with-errors"/>
                                            </div>
                                        </div>*/}
                                        <div className="form-group">
                                            <div className="controls">
                                                <textarea id="message" rows="4" placeholder="Message" value={message} onChange={e=> setMessage(e.target.value)} className="form-control" required="" data-error="Write your message"/>
                                                <div className="help-block with-errors"/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="controls">
                                                <ReCAPTCHA
                                                    ref={recaptchaRef}
                                                    sitekey={recaptchaSiteKey}
                                                    onChange={setRecaptcha}
                                                />
                                            </div>
                                        </div>
                                        {!emailSent ?
                                            <div className="text-center mt-md-5">
                                                <button type="submit" id="submit" className="btn btn-purple btn-pill text-uppercase disabled" >Submit</button>
                                                <div id="msgSubmit" className="h5 text-center hidden"/>
                                            </div>
                                                :
                                            <div className="text-center mt-md-5">
                                                <button type="submit" id="submit" className="btn btn-purple btn-pill text-uppercase disabled pointer-events-none" disabled={true}> Sent! </button>
                                            </div>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <form hidden className="subscribe-from form-inline row mt-5 wow fadeInUp animated" data-wow-delay=".3s">
                        <div className="form-group col-md-4">
                            <h4 className="m-0 text-uppercase">Subscribe</h4>
                            <small>Never miss our any news and updates</small>
                        </div>
                        <div className="form-group col-sm-5">
                            <input type="email" className="form-control w-100" placeholder="Enter your email address"/>
                        </div>
                        <div className="form-group col-sm-3">
                            <button type="submit" className="btn w-100 text-uppercase btn-primary-solid">subscription</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>    
</div>
    )
}

export default Contact
