import React from 'react';

const WhyStake =  () => {

    const blockData = [
        {
            title:"Design",
            icon:"bi-bond",
            text:"Formidable hybrid cloud setup into dedicated servers in a private cage with biometric access control and has two relays for high availability, 1 Active BP and 1 Standby, with cold keys stored in secret key vaults.",
        },
        {
            title:"Security",
            icon:"bi-shield",
            // text:"In regards to security, all servers have different SSH ports, disabled root access, key pairs with extremely secure passwords, network exclusions that only allow given IPs to connect, backup policies, and others.",
            text:"All servers have different SSH ports, disabled root access, key pairs with extremely secure passwords, network exclusions that only allow given IPs to connect, backup policies, and others.",
        },
        {
            title:"Resilience",
            icon:"bi-refresh-time",
            text:"$SCAN Stake Pool has an active-active topology for the relays, and an active-passive topology for the Block Producer nodes. This allows higher availability, fault tolerance, and the easiness to recover from outages.",
        },
        {
            title:"Software versions",
            icon:"bi-office-bag ",
            text:"The relays and Block Producers are running on cardano Air gap machines-node and cardano-cli on version 1.29.0.",
        },
    ];


    return (
        <div id="why">    
    <section className="section-gap">
        <div className="container">            
            <div className="row text-center">
                <div className="col-12">
                    <div className="section-title" style={{marginBottom: "3rem"}}>
                        <h3 className="tb-f36-lg tb-f30-sm tb-font-name tb-big-heading tb-mt-5 tb-mt-3-sm tb-m0 mb-md-3">Why stake with us</h3>
                    </div>
                </div>
            </div>
            <div className="row text-md-left text-center">
                {blockData.map(({title, icon, text}, i) => (
                    <div className="col-md-6 col-sm-6 wow fadeInUp text-center px-md-5" key={title.replaceAll(' ', '_')} data-wow-delay={(.8 - i/10) +'s'}>
                        <i className={`bi ${icon} f-icon text-paste-color`}/>
                        <h5 className="my-4 text-semi-dark-blue">{title}</h5>
                        <p>{text}</p>
                    </div>
                ))}
            </div>
        </div>
    </section>    
</div>
    );
}


export default WhyStake;