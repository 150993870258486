import React from 'react';


const Header = () => {
    return (

<header className="app-header navbar-purple">
    <div className="container">
        <div className="row">
            <div className="col-12">
                <nav className="navbar navbar-expand-md" id="mainNav">                    
                    <a className="navbar-brand mr-5" href="/">                        
                        <h4 className="text-white">Scan Token</h4>
                    </a>                    
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon">
                            <i className="fa fa-bars"> </i>
                        </span>
                    </button>
                    

                    
                    <div className="collapse navbar-collapse" id="navbarsExampleDefault">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a className="nav-link js-scroll-trigger" href="#calculator">Airdrop</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link js-scroll-trigger" href="#countdown">Pool</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link js-scroll-trigger" href="#contact">Contact</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link js-scroll-trigger" href="#faq">FAQ</a>
                            </li>
                            <li className="nav-item dropdown">
                                <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    More
                                </span>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <a className="dropdown-item js-scroll-trigger" href="https://repocoin.io" target='_blank' rel="noreferrer">repocoin.io</a>
                                    {/*<a className="dropdown-item js-scroll-trigger" href="#how_works">How it Works</a>                                    */}
                                </div>
                            </li>
                            
                        </ul>
                    </div>
                    
                </nav>
            </div>
        </div>
    </div>
</header>

    );
}

export default Header;


