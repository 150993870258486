import React, {useEffect, useState} from 'react';
import { Pie } from 'react-chartjs-2';
import './Tokenomics.styles.scss';
const data = {
    labels: ['Initial Stake Offering & App Rewards (90%) ', 'Founders (5%)', 'Marketing (5%)'],
    datasets: [
        {
            label: '',
            data: [90,5,5],
            backgroundColor: [
                'rgba(74, 98, 255, 0.8)',
                'rgba(54, 162, 235, 0.8)',
                'rgba(255, 206, 86, 0.8)',
            ],
            borderColor: [
                'rgba(74, 98, 255, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

// plugins.legend.position
function Tokenomics() {
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    let isMobile = (width <= 768);
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    },[])


    return (
        <section className="section-gap pb-0" id={"tokenomics"}>
            <div className="container">

                <div className="row text-center">
                    <div className="col-12">
                        <div className="section-title">
                            <h2 className="wow fadeInUp" data-wow-delay=".2s">Tokenomics</h2>
                            <p style={{fontSize:'24px', marginTop:'10px'}}>Max supply is 9 Billion SCAN </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-gap chart-wrap">
                        <Pie data={data} options={
                            {
                                plugins:{
                                    legend:{
                                        position: (width <= 768) ? 'top' : 'right',
                                        labels: {
                                            padding:(width <= 768) ? 10 :60,
                                            font: {
                                                size: (width <= 768) ? 14:20
                                            }
                                        }
                                    }
                                }
                            }
                        }/>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Tokenomics;