import React, {useContext, useEffect, useState} from 'react';
import GlobalSummaryContext from "../../Contexts/GlobalSummaryContext";


const Calculator = () => {
    const [epochStart, setEpochStart]= useState('');
    const    [epochLaunch, setEpochLaunch]= useState('');
    const   [stakedAda, setStakeAda]= useState('');
    const   [calculated, setCalculated]= useState(0);
    const {epoch_last} = useContext(GlobalSummaryContext);
    useEffect(() => {
        if (epoch_last){
            setEpochStart(parseInt(epoch_last));
            setEpochLaunch(parseInt(epoch_last) + 6);
            setStakeAda(1000);
        }
    }, [epoch_last]);
    const calculate = (e) => {
        if(e){
            e.preventDefault();
        }
        const start = parseInt(epochStart);
        const launched = parseInt(epochLaunch);
        const staked = parseInt(stakedAda);

        if(launched <= start){
            alert("Epoch Start cannot be lower than epoch launch");
            return false;
        }
        //calculate
        setCalculated( ((launched - start) * (staked/10)) );
    }

    return (
        <div id="calculator">
            <section className="section-gap">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-12">
                            <div className="section-title" style={{marginBottom: "3rem"}}>
                                <h3 className="tb-f36-lg tb-f30-sm tb-font-name tb-big-heading tb-mt-5 tb-mt-3-sm tb-m0 mb-md-3">Staking Rewards Calculator</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="col-lg-7 col-md-12 col-gap">
                            <div className="card px-md-5 text-center pt-md-4 mb-sm-5 wow fadeInUp" data-wow-delay=".4s">
                                <div className="card-body">
                                    <form action="#" onSubmit={calculate}>
                                        <div className="input-group mb-3">
                                            <input type="number" required min="1" className="form-control" id="epoch-start" placeholder="Starting Epoch" onChange={e =>  setEpochStart(e.target.value)} value={epochStart}/>
                                                <div className="input-group-append">
                                                    <span className="input-group-text">Starting Epoch</span>
                                                </div>
                                        </div>
                                        <div className="input-group mb-3">
                                            <input type="number" required className="form-control" id="epoch-launch" placeholder="Launch Epoch" value={epochLaunch} onChange={e => setEpochLaunch(e.target.value)}/>
                                                <div className="input-group-append">
                                                    <span className="input-group-text">&nbsp;Launch Epoch</span>
                                                </div>
                                        </div>
                                        <div className="input-group mb-3">
                                            <input type="number" required className="form-control" id="staked-ada" placeholder="Staked ADA" value={stakedAda} onChange={e => setStakeAda(e.target.value)}/>
                                                <div className="input-group-append">
                                                    <span className="input-group-text">Staked ADA &nbsp; &nbsp; &nbsp; </span>
                                                </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-sm-12">

                                            </div>
                                        </div>
                                        <button id="calculate-btn" className="btn btn-purple btn-purple-shadow btn-pill text-uppercase mb-md-4 wow fadeInUp" data-wow-delay=".6s" >Calculate</button>
                                    </form>
                                </div>
                            </div>

                            <div className="row" hidden={!calculated}>
                                <div className="col-md-12 animated fadeIn">
                                    <h5 className="text-purple-color">SCAN rewards ≈ {calculated} SCAN*</h5>
                                    <small>* Calculated SCAN rewards based on starting Epoch launch at {epochStart} is {calculated} SCAN</small>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Calculator;