import React, {useEffect} from 'react';
// import Particles from 'react-particles-js';
import 'particles.js';
import Lottie from 'react-lottie';
import animationData from '../../anim/repo.json';
import './Banner.styles.scss'
import ParticleData from "./ParticleData";
const handleCopy = e => {
    navigator.clipboard.writeText('asset166rlj0f428sdtsehrclzm4jkz9e8wu50r8j8m7');
    const that = window.$(e.target);
    that.attr('title', 'Copied!!')
        .tooltip('dispose')
        .tooltip('show');
    setTimeout(function(){
        that.attr("title", "Click to copy").tooltip('dispose')
            .tooltip();
    }, 3*1000);
    return 1;
}
const Banner = () => {
    useEffect(() => {              
        window.particlesJS("particles-js", ParticleData)
    }, []);

    return (
        <div className="hero-banner creative-banner" id="banner">
    <div className="bubble-set">
        <div className="bubble-lg"/>
        <div className="bubble-md"/>
        <div className="bubble-sm"/>
    </div>

    <div id="particles-js"/>
        
    <div className="hero-text ">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-7 col-md-12">
                    <h1 className="hero-title wow fadeInUp text-light" data-wow-delay=".2s">Delegate your ADA & Earn SCAN Too!</h1>
                    <p className="here-sub-title wow fadeInUp text-light" data-wow-delay=".3s">
                        By delegating ADA in our SCAN pool,
                        you will receive rewards in ADA & SCAN
                    </p>
                    <div className="mb-3 mb-lg-3">                        
                        {/*<button className="btn btn-white btn-pill mb-3 text-uppercase wow fadeInUp" data-wow-delay=".5s">How it works</button>*/}
                    </div>                    
                </div>
                <div className="col-lg-5 col-md-12 text-center" data-wow-delay=".6s" id={'banner-anim-wrap'}>
                    <Lottie options={{
                        loop: false,
                        autoplay: true,
                        animationData: animationData,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }} className={"banner-img"} id={'banner-anim'}/>
                    <div className="cardano flex flex-wrap align-content-center align-items-center justify-content-center">
                        <img src="/assets/img/cardano.png" alt="Cardano wallet" className={'cardano-logo w-full'}/>
                        <p className="mb-0 font-size-sm pool-id bg-white rounded py-2">asset166rlj0f428sdtsehrclzm4jkz9e8wu50r8j8m7 <i className="bi bi-files text-navy-blue ml-1 text-bold" data-toggle="tooltip" data-placement="right" title="Click to copy" onClick={handleCopy}/></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="hero-footer" style={{backgroundImage: 'url(assets/img/banner-curve.png)'}}/>
</div>
    );
}

export default Banner;