import './App.css';
import {WOW} from 'wowjs';
import React, {useEffect, useState} from 'react';
import Header from './sections/header/header.component';
import Banner from './sections/banner/Banner.component';
import WhyStake from './sections/whyStake/WhyStake.component';
import CountDown from './sections/Countdown/Countdown.component';
import Ticker from './sections/Ticker/Ticker.component';
import Calculator from './sections/Calculator/Calculator.component';
// import HowItWorks from './sections/HowItWorks/HowItWorks.component';
import Faq from './sections/Faq/Faq.component';
import Contact from './sections/Contact/Contact.component';
import SummaryContext from "./Contexts/SummaryContext";
import GlobalSummaryContext from "./Contexts/GlobalSummaryContext";
import Announcement from "./sections/Annoucement/Announcement.component";
import Tokenomics from "./sections/Tokenomics/Tokenomics.component";
import AppPrompt from "./sections/AppPrompt/AppPrompt.component";

function App() {

  const [summary,setSummary] = useState({});
  const [global,setGlobal] = useState({});

  useEffect(() => {
    // console.log("App initiated");

    fetch('https://js.adapools.org/pools/1b229561688d2d9f2e9d16fb7eea258c2d764eeecb6e5d6743f3e2b4/summary.json')
        .then(res => res.json())
        .then(({data}) => setSummary(data));
  fetch('https://js.adapools.org/global.json')
      .then(res => res.json())
      .then(({epoch_last,ended_before,epoch_started}) => setGlobal({epoch_last,ended_before,epoch_started}));
    new WOW().init();
    if (window.$(window).width() <= 991){
      window.$(".wow").removeClass("wow");
    }
    const tag = document.createElement("script");
    tag.src = "/assets/js/scripts.js";    
    setTimeout(() => (document.getElementsByTagName("head")[0].append(tag)), 100);    
  },[]);

  return (
      <GlobalSummaryContext.Provider value={global}>
          <SummaryContext.Provider value={summary}>
            <Header/>
            <Banner/>
              <Announcement/>
            <WhyStake/>
            <AppPrompt/>
            <CountDown/>
            <Ticker/>
            <Calculator/>
            <Faq/>
            <Tokenomics/>
            <Contact/>
          </SummaryContext.Provider>
      </GlobalSummaryContext.Provider>
  );
}

export default App;
