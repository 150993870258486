
import React from 'react'

function Faq() {
    return (
        <section className="section-gap pb-0" id={"faq"}>
    <div className="container">
        
        <div className="row text-center">
            <div className="col-12">
                <div className="section-title">
                    <h2 className="wow fadeInUp" data-wow-delay=".2s">Frequently Asked Question</h2>
                    
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6 col-gap">
                <div className="accordion wow fadeInUp" data-wow-delay=".4s" id="accordion-1" >
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                <a className="collapsed" data-toggle="collapse" data-target="#collapse-1-1" aria-expanded="false">
                                    How do I stake with SCAN?
                                </a>
                            </h5>
                        </div>
                        <div id="collapse-1-1" className="collapse" data-parent="#accordion-1" >
                            <div className="card-body pt-0">
                                <ol>
                                    <li>You will first need to transfer ADA funds to an official wallet such as Daedalus or Yoroi.</li>
                                    <li>Once your funds are in either wallet, go to the Delegation section in your wallet and search for ticker SCAN.</li>
                                    <li>Select the SCAN pool you wish to delegate to and click the "Delegate" button.</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                <a className="collapsed" data-toggle="collapse" data-target="#collapse-1-2">
                                    When will I receive rewards?
                                </a>
                            </h5>
                        </div>
                        <div id="collapse-1-2" className="collapse" data-parent="#accordion-1">
                            <div className="card-body pt-0">
                                <p>
                                    SCAN tokens are rewarded only after a successful Epoch has minted a block. It will take 4 epochs (15-20 days) to begin receiving rewards.  This delay is built into the Cardano protocol and known as the "delegation cycle".  You will receive rewards each epoch (5 days) thereafter.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                <a className="collapsed" data-toggle="collapse" data-target="#collapse-1-3">
                                    How can I view and confirm my rewards?
                                </a>
                            </h5>
                        </div>
                        <div id="collapse-1-3" className="collapse" data-parent="#accordion-1">
                            <div className="card-body pt-0">
                                <ol>
                                    <li>Visit www.adapools.org and locate the SCAN pool to which you're delegated</li>
                                    <li>Click on the "Delegators" tab on the pool's detail page</li>
                                    <li>Sort by "Amount" and locate the amount that matches your wallet. You may hover over the actual amount for more detail.</li>
                                    <li> You will see the first epoch delegated to the pool next to the stake address. (This is your stake address.)</li>
                                    <li>Click on the stake address to view delegation and rewards history.</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                <a className="collapsed" data-toggle="collapse" data-target="#collapse-1-4">
                                    How do pool fees work?
                                </a>
                            </h5>
                        </div>
                        <div id="collapse-1-4" className="collapse" data-parent="#accordion-1">
                            <div className="card-body pt-0">
                                <p>
                                    Each epoch the 340 ADA fixed fee (which is the protocol minimum) is taken from all rewards generated by the pool. Next, the pool variable fee (currently 0%) is removed from all rewards. Finally, the remainder is distributed to delegators prorata (based on relative stake).
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-gap">

                <div className="accordion wow fadeInUp" data-wow-delay=".5s" id="accordion-2" >
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                <a className="collapsed" data-toggle="collapse" data-target="#collapse-2-1">
                                    Which pool should I delegate to?
                                </a>
                            </h5>
                        </div>
                        <div id="collapse-2-1" className="collapse" data-parent="#accordion-2">
                            <div className="card-body pt-0">
                                <p>We recommend delegating to an unsaturated SCAN pool. Your rewards will begin to diminish when pool saturation exceeds 100%.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-header">
                            <h5>
                                <a className="collapsed" data-toggle="collapse" data-target="#collapse-2-3">
                                    How can I participate in the SCAN community?
                                </a>
                            </h5>
                        </div>
                        <div id="collapse-2-3" className="collapse" data-parent="#accordion-2">
                            <div className="card-body pt-0">
                                <div className="font_8">Please join our <a href="https://t.me/REPO_Blockchain" target="_blank" rel="noreferrer">Telegram group</a> for frequent updates, support and to join our community.</div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                <a className="collapsed" data-toggle="collapse" data-target="#collapse-2-4">
                                    Where can I view SCAN pool details including performance, saturation, blocks produced, etc?
                                </a>
                            </h5>
                        </div>
                        <div id="collapse-2-4" className="collapse" data-parent="#accordion-2">
                            <div className="card-body pt-0">
                                <p>Pool index sites such as the following provide detailed information about pools:</p>
                                <ol>
                                    <li>www.adapools.org</li>
                                    <li>pooltool.io</li>
                                    <li>cardanoscan.io</li>
                                </ol>
                                Visit <a href="https://pool.pm" target="_blank" rel="noreferrer">pool.pm</a> to view real-time pool performance.
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            
        </div>
    </div>
</section>
    )
}

export default Faq
