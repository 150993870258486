import React from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import "slick-carousel/slick/slick-theme.css";
import './AppPrompt.styles.scss';
import animationData from "../../anim/scan.json";
import Lottie from "react-lottie";

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows:false,
    autoplay:true,
    slidesToShow: 1,
    slidesToScroll: 1
};
const AppPrompt = () => (
    <div id="app">
        <section className="section-gap">
            <div className="container">
                <div className="row text-center">
                    <div className="col-12">
                        <div className="section-title" style={{marginBottom: "3rem"}}>
                            <h3 className="tb-f36-lg tb-f30-sm tb-font-name tb-big-heading tb-mt-5 tb-mt-3-sm tb-m0 mb-md-3">
                                Mobile app coming soon
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="row text-md-left text-center">
                    <div className="col-md-7 col-sm-7 wow fadeInUp text-center px-md-5 d-flex flex-wrap align-items-center align-content-center justify-content-center mb-20">
                        <h3 className={'w-100'}>
                            SCAN tokens power the Repo App
                        </h3>
                        <h5  className={'w-100'}>
                            Learn more at <a href="https://repocoin.io" target={'_blank'}>repocoin.io</a>
                        </h5>
                        <div className="scan">
                            <Lottie options={{
                                loop: true,
                                autoplay: true,
                                animationData: animationData,
                            }} />
                        </div>

                    </div>
                    <div className="col-md-5 col-sm-5 wow fadeInUp text-center px-md-5">

                        {/*<Slider {...settings}>*/}
                        {/*    {[1,2,3].map(i => <img src={`/assets/img/ss_${i}.jpg`} key={`ss_${i}`} alt="" className={'alter'}/>)}*/}
                        {/*</Slider>*/}
                        <div className="phone">
                            <div className="screen">
                                <Slider {...settings}>
                                    {[1,2,3].map(i => <img src={`/assets/img/ss_${i}.png`} key={`ss_${i}`} alt=""/>)}
                                </Slider>
                            </div>
                        </div>
                        <p className={'p-0 m-0 animated infinite pulse text-bold'}>Swipe to find out</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
);

export default AppPrompt;