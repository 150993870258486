import React, {useEffect, useState} from "react";
import './Timer.styles.scss';

const Timer = ({end}) =>{

    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);



    const calculateTimeLeft = () => {
        const difference = end - (+new Date());
        if (difference > 0) {
            setDays(
                Math.floor(difference / (1000 * 60 * 60 * 24))
            );
            setHours(
                Math.floor((difference / (1000 * 60 * 60)) % 24)
            );
            setMinutes(
                Math.floor((difference / 1000 / 60) % 60)
            );
            setSeconds(
                Math.floor((difference / 1000) % 60)
            );
        }

    };


    useEffect(() => {
        setTimeout(() => {
            calculateTimeLeft();
        }, 1000);
    });

    return (
        <div className="timer">
            <div className="countdown-wrapper countdown-wrapper-theme-dark">
                <div className="countdown-item">
                    <div><span>{days}</span>days</div>
                    <div><span>{hours}</span>hours</div>
                    <div><span>{minutes}</span>minutes</div>
                    <div><span>{seconds}</span>seconds</div>
                </div>
            </div>
        </div>
    );
}

export default Timer;