import React from "react";
import Lottie from 'react-lottie';
import animationData from "../../anim/live.json";
import chestAnimationData from '../../anim/chest.json';
import './Annoucement.styles.scss';
const Announcement = () => (
    <section className="section-gap" id={"announcement"}>
        <div className="container">
            <div className="row text-center">
                <div className="col-12">
                    <div className="section-title d-flex justify-content-center flex-wrap" style={{marginBottom: "3rem"}}>
                        <h3 className="tb-f36-lg tb-f30-sm tb-font-name tb-big-heading tb-mt-5 tb-mt-3-sm tb-m0 mb-md-3 justify-content-center d-flex w-100">Initial Stake Offering Going On <Lottie options={{
                            className:'live-dot',
                            loop: true,
                            autoplay: true,
                            animationData: animationData,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice"
                            }
                        }} width={40} height={40} style={{margin:0}}/></h3>

                    </div>
                </div>
            </div>
            <div className="row ">
                <div className="col-md-6 flex-1 flex-column justify-content-center align-content-center align-items-center d-flex left">
                    <p style={{fontSize: "24px"}}><b>Rewards in ADA & SCAN Tokens, because 2 are better than 1! The more Ada you delegate, the more ADA & SCAN you will earn.</b></p>
                </div>
                <div className="col-8 col-md-6 text-center d-flex justify-content-center flex-1">
                    <div className="chest-wrap">
                        <Lottie options={{
                            loop: true,
                            autoplay: true,
                            animationData: chestAnimationData,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice"
                            }
                        }} style={{margin:0, transform:"translateY(-100px)", maxWidth:"300px"}}/>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

export default Announcement;